export default class Products {
  constructor() {
    var tabs = $('.products__left__header__tab');
    var contents = $('.products__left__content');

    tabs.on('click', (e) => {
      let index = jQuery.inArray(e.currentTarget, tabs);

      $('.selected').removeClass('selected');

      $(tabs[index]).addClass('selected');
      $(contents[index]).addClass('selected');
    });
  }
}
