import 'core-js/stable'
import 'regenerator-runtime/runtime'
import objectFitImages from 'object-fit-images'
import 'slick-carousel'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Accordions from './accordions'
import Documentation from './documentation'
import Gallery from './gallery'
import Products from './products'
import Table from './table'
import Events from './events'
import Video from './video'
import Hero from './hero'
import Translation from './translation'

new Accordions()
new Documentation()
new Gallery()
new Products()
new Table()
new Translation()
new Events()
new Video()

if ($('.home-hero').length > 0 && window.innerWidth >= 960) {
  new Hero()
}

import { initMenuHandlers } from './header'

initMenuHandlers()
objectFitImages()
