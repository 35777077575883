import debounce from 'lodash.debounce'
import Fuse from 'fuse.js'

export default class Documentation {
  constructor() {
    this.docs = document.querySelector('.documentation')
    if (!this.docs) return

    $('.search-menu__controls__clear').hide()

    this.allDocuments = Array.from(this.docs.querySelectorAll('.document'))
    this.allCheckboxes = Array.from(
      this.docs.querySelectorAll('.item-input__checkbox'),
    )
    this.searchInput = this.docs.querySelector(
      '.search-menu__controls__input-container__input',
    )
    this.mobileMenuButton = this.docs.querySelector(
      '.documentation__mobile-search',
    )
    this.mobileSearchMenu = this.docs.querySelector(
      '.documentation__search-menu',
    )
    this.clearFiltersButton = this.docs.querySelector(
      '.search-menu__controls__clear',
    )
    this.expandButtons = Array.from(
      this.docs.querySelectorAll('.expand__heading__icon'),
    )
    this.itemInputs = Array.from(this.docs.querySelectorAll('.item-input'))

    this.activeFilters = []

    this.fuseOptions = {
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 2,
      keys: ['name'],
    }

    this.initializeListeners()
  }

  toggleMobileVisibility = () => {
    this.mobileSearchMenu.classList.toggle('search-menu--visible')
  }

  resetForm = () => {
    this.allCheckboxes.forEach((checkbox) => (checkbox.checked = false))
    this.searchInput.value = ''
    this.activeFilters = []

    this.toggleClearFilter()

    this.filterDocs()
  }

  handleCheckboxClick = (event) => {
    const filterName = event.target.name

    if (!this.activeFilters.includes(filterName))
      this.activeFilters.push(filterName)
    else
      this.activeFilters = this.activeFilters.filter(
        (filter) => filter !== filterName,
      )

    this.filterDocs()
  }

  filterDocs = () => {
    const filteredDocs = this.allDocuments.filter((doc) => {
      doc.style.display = 'none'
      const { type, product, solution } = doc.dataset

      if (this.activeFilters.length === 0) return true

      if (
        this.activeFilters.includes(type) ||
        this.activeFilters.includes(product) ||
        this.activeFilters.includes(solution)
      ) {
        return true
      }

      return false
    })

    const searchQuery = this.searchInput.value
    const fuseList = filteredDocs.map((doc) => ({
      name: doc.querySelector('.document__info__heading').innerText,
      node: doc,
    }))

    const fuse = new Fuse(fuseList, this.fuseOptions)
    const searchResults =
      searchQuery === ''
        ? filteredDocs
        : fuse.search(searchQuery).map((result) => result.item.node)

    searchResults.forEach((result) => (result.style.display = 'flex'))
  }

  handleExpandClick = (event) => {
    const expandItems = event.target.parentNode.nextSibling
    expandItems.classList.toggle('expand__items--hidden')
    event.target.classList.toggle('expand__heading__icon--rotate')
  }

  toggleClearFilter = (event) => {
    if (
      $('input:checked').length ||
      $('.search-menu__controls__input-container__input').val()
    ) {
      $('.search-menu__controls__clear').show()
    } else {
      $('.search-menu__controls__clear').hide()
    }
  }

  initializeListeners = () => {
    this.mobileMenuButton.addEventListener('click', this.toggleMobileVisibility)
    this.clearFiltersButton.addEventListener('click', this.resetForm)
    this.allCheckboxes.forEach((checkbox) =>
      checkbox.addEventListener('click', this.handleCheckboxClick),
    )
    this.searchInput.addEventListener('input', debounce(this.filterDocs, 250))
    this.expandButtons.forEach((button) =>
      button.addEventListener('click', this.handleExpandClick),
    )

    $('.search-menu__controls__input-container__input').on(
      'keyup',
      this.toggleClearFilter,
    )

    this.itemInputs.forEach((item) => {
      item.addEventListener('click', this.toggleClearFilter)
    })
  }
}
