export default class Translation {
  constructor() {
    $('.multilang-active').on('click', (event) => {
      event.preventDefault();
    })

    if( $('.multilang-active').length == 0 ) {
      $('.multilang-clear').on('click', (event) => {
        event.preventDefault();
      })

      $('.multilang-clear').css('color', '#0D96D3').css('cursor', 'default');
    }
  }
}
