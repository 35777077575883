export default class Accordions {
  constructor() {
    const group = $('.products__accordions')

    group.each((_, group) => {
      const buttons = $(group).find('button.products__accordion__header')

      buttons.each((_, button) => {
        $(button).on('click', (e) => {
          const target = $(e.currentTarget)
          this.toggleAccordion(target)

          const siblings = target.parent().siblings()
          siblings.each((_, sibling) => {
            const siblingButton = $(sibling).find(
              'button.products__accordion__header',
            )

            if (siblingButton.hasClass('selected')) {
              this.toggleAccordion(siblingButton)
            }
          })
        })
      })
    })
  }

  toggleAccordion(target) {
    target.toggleClass('selected')
    target.next().slideToggle(300)

    if (target.hasClass('selected')) {
      target.attr('aria-label', 'Hide Content')
    } else {
      target.attr('aria-label', 'Show Content')
    }
  }
}
