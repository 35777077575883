import TweenMax from 'TweenMax';
import ScrollMagic from 'ScrollMagic';
import 'animation.gsap';
import 'debug.addIndicators';

export default class Hero {
  constructor() {
    if (window.innerWidth >= 960) {
      this.controller = new ScrollMagic.Controller();

      var image = new ScrollMagic.Scene({
        duration: $('.home-hero').height(),
        offset: 0
      })
        .setTween(".home-hero__image", 1, {top: "250px"})
        .addTo(this.controller);

      var outline = new ScrollMagic.Scene({
        duration: $('.home-hero').height(),
        offset: 0
      })
        .setTween(".home-hero__outline", 1, {top: "150px"})
        .addTo(this.controller);

      var callout = new ScrollMagic.Scene({
        duration: $('.home-hero').height(),
        offset: 0
      })
        .setTween(".home-hero__callout", 1, {top: "300px"})
        .addTo(this.controller);

      var background = new ScrollMagic.Scene({
        duration: $('.home-hero').height(),
        offset: 0
      })
        .setTween(".home-hero__background", 1, {top: "25px"})
        .addTo(this.controller);
    }
  }
}
