export default class Gallery {
  constructor() {
    this.prevArrow = `
      <button type="button" class="gallery__nav gallery__prev">
        <svg id="8dbc9ba3-cc32-497a-ba60-b809f0cffa02" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 58"><title>left-arrow</title><rect id="9a585a41-3b87-48e1-97f0-04d9bb2607ba" data-name="Rectangle-6-Copy-3" width="77" height="58" style="fill:#fff"/><polyline id="c2e39d72-b37b-4fbf-a573-e3ada81788b2" data-name="Stroke-1-Copy" points="45 16 32 29 45 42" style="fill:none;stroke:#0d96d3;stroke-width:3px"/></svg>
      </button>
    `;

    this.nextArrow = `
      <button type="button" class="gallery__nav gallery__next">
        <svg id="0f98d190-6659-40c0-8fa3-f11aaf439b6c" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 77 58"><title>right-arrow</title><rect id="cbb3bfc3-3651-44b8-aba0-dff76020239f" data-name="Rectangle-6-Copy-2" width="77" height="58" style="fill:#fff"/><polyline id="84800117-d109-4684-a383-bf603a2cfb80" data-name="Stroke-1" points="32 16 45 29 32 42" style="fill:none;stroke:#0d96d3;stroke-width:3px"/></svg>
      </button>
    `;

    this.initSliders();
  }

  initSliders() {
    $('.gallery').slick({
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: this.prevArrow,
      nextArrow: this.nextArrow,
      adaptiveHeight: true,
    });
    $('.gallery').css({"opacity": "1"});
  }
}