import debounce from 'lodash.debounce'

const handleToggleButtonClick = (event) => {
  let button = event.target
  button = button.closest('.header__bar__mobile-toggle-button') || button

  const header = button.closest('.header')
  if (header) header.classList.toggle('header--open')

  const icon = button.querySelector('.header__hamburger-icon')
  if (icon) icon.classList.toggle('header__hamburger-icon--open')
}

const handleMultilangButtonClick = (event) => {
  document.querySelector('.header__bar__multilang-button').blur()

  let button = event.target
  button = button.closest('.header__bar__multilang-button') || button
  button.classList.toggle('header__bar__multilang-button--open')

  const options = button.querySelector(
    '.header__bar__multilang-button__options',
  )
  if (options)
    options.classList.toggle('header__bar__multilang-button__options--open')
}

const handleScroll = (prevScrollPos) => {
  let currentScrollPos = window.pageYOffset
  let translateBtnOpen = document.querySelector(
    '.header__bar__multilang-button__options--open',
  )

  if (prevScrollPos !== currentScrollPos && translateBtnOpen) {
    document.querySelector('.header__bar__multilang-button').blur()

    document
      .querySelector('.header__bar__multilang-button__options')
      .classList.remove('header__bar__multilang-button__options--open')
  }

  const header = document.querySelector('.header')
  if (!header) return

  const isAtTop = window.scrollY <= 0

  if (isAtTop) header.classList.remove('header--floating')
  else header.classList.add('header--floating')
}

export const initMenuHandlers = () => {
  const prevScrollPos = window.pageYOffset

  const toggleButton = document.querySelector(
    '.header__bar__mobile-toggle-button',
  )
  if (toggleButton)
    toggleButton.addEventListener('click', handleToggleButtonClick)

  const multilangButton = document.querySelector(
    '.header__bar__multilang-button',
  )
  if (multilangButton)
    multilangButton.addEventListener('click', handleMultilangButtonClick)

  // Need to fire off the initial handler on page load before scrolling.
  handleScroll(prevScrollPos)
  window.addEventListener(
    'scroll',
    debounce(handleScroll, 100, { leading: true }),
    {
      passive: true,
    },
  )
}
